import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Footer, SocialLinks } from '@components';
import './footer-container.scss';

const FooterContainer = (props) => (
  <Footer {...props}>
    <footer id="footer" role="contentinfo" className="on-footer">
      <div className="on-footer-inner" id="yui_3_17_2_1_1623015428913_121">
        <div className="on-sqs-row on-footer-container" id="yui_3_17_2_1_1623015428913_120">
          <div
            className="on-column-width-3 on-footer-logo-and-copyright-container"
            style={{ textAlign: 'center' }}
            id="yui_3_17_2_1_1623015428913_119">
            <img
              src="//static1.squarespace.com/static/5e0e87c3d133877c89324d51/t/5e4415ae481e936b2aaafa2b/1622614319549/"
              style={{ width: '100%' }}
              className="on-footer-logo"
              aria-hidden="true"
              alt="company logo and copyright area"
              data-uw-rm-ima-original="company logo and copyright area"
            />
            <div
              className="on-sqs-layout on-sqs-grid-12 on-columns-12"
              data-layout-label="Footer Copyright"
              data-type="block-field"
              data-updated-on="1582251073156"
              id="ylopoFooterCopyrightBlock">
              <div className="on-row on-sqs-row" id="yui_3_17_2_1_1623015428913_118">
                <div className="on-col on-sqs-col-12 on-span-12" id="yui_3_17_2_1_1623015428913_117">
                  <div
                    className="on-sqs-block on-image-block on-sqs-block-image on-sqs-text-ready"
                    data-block-type="5"
                    id="block-yui_3_17_2_1_1581521331890_12110">
                    <div className="on-sqs-block-content" id="yui_3_17_2_1_1623015428913_116">
                      <div
                        className="on-image-block-outer-wrapper on-layout-caption-below on-design-layout-inline on-combination-animation-none on-individual-animation-none on-individual-text-animation-none on-sqs-narrow-width"
                        data-test="image-block-inline-outer-wrapper"
                        id="yui_3_17_2_1_1623015428913_115">
                        <figure
                          className="on-sqs-block-image-figure on-intrinsic "
                          style={{ maxWidth: '2500px' }}
                          id="yui_3_17_2_1_1623015428913_114">
                          <div
                            style={{ 'padding-bottom': '38.04%', overflow: 'hidden' }}
                            className="on-image-block-wrapper on-has-aspect-ratio "
                            data-animation-role="image"
                            id="yui_3_17_2_1_1623015428913_113">
                            <noscript>
                              <img
                                src="https://images.squarespace-cdn.com/content/v1/5e0e87c3d133877c89324d51/1582251063665-2Y3VV1AZR4FGYRRXECMF/ke17ZwdGBToddI8pDm48kGB1Vu1IO5PKLGYtbkYEbFcUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnGl457m_G43SitDjFtlE1qr-5leHoQj4TDu8Gen4B2CoE2wSA8xtZMg3gWW8ualvjQ/eXpRealty-Black+-+Transparent.png"
                                alt="eXpRealty-Black - Transparent.png"
                              />
                            </noscript>
                            <img
                              className="on-thumb-image on-loaded"
                              data-src="https://images.squarespace-cdn.com/content/v1/5e0e87c3d133877c89324d51/1582251063665-2Y3VV1AZR4FGYRRXECMF/ke17ZwdGBToddI8pDm48kGB1Vu1IO5PKLGYtbkYEbFcUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnGl457m_G43SitDjFtlE1qr-5leHoQj4TDu8Gen4B2CoE2wSA8xtZMg3gWW8ualvjQ/eXpRealty-Black+-+Transparent.png"
                              data-image="https://images.squarespace-cdn.com/content/v1/5e0e87c3d133877c89324d51/1582251063665-2Y3VV1AZR4FGYRRXECMF/ke17ZwdGBToddI8pDm48kGB1Vu1IO5PKLGYtbkYEbFcUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnGl457m_G43SitDjFtlE1qr-5leHoQj4TDu8Gen4B2CoE2wSA8xtZMg3gWW8ualvjQ/eXpRealty-Black+-+Transparent.png"
                              data-image-dimensions="2500x951"
                              data-image-focal-point="0.5,0.5"
                              data-load="false"
                              data-image-id="5e4f3c363294823adf5bdae4"
                              data-type="image"
                              aria-hidden="true"
                              alt="company logo and copyright area"
                              data-image-resolution="300w"
                              src="https://images.squarespace-cdn.com/content/v1/5e0e87c3d133877c89324d51/1582251063665-2Y3VV1AZR4FGYRRXECMF/ke17ZwdGBToddI8pDm48kGB1Vu1IO5PKLGYtbkYEbFcUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnGl457m_G43SitDjFtlE1qr-5leHoQj4TDu8Gen4B2CoE2wSA8xtZMg3gWW8ualvjQ/eXpRealty-Black+-+Transparent.png?format=300w"
                              style={{
                                left: '0%',
                                top: '-0.282759%',
                                width: '100%',
                                height: '100.566%',
                                position: 'absolute',
                              }}
                              data-uw-rm-ima-original="company logo and copyright area"
                            />
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    className="on-sqs-block on-html-block on-sqs-block-html"
                    data-block-type="2"
                    id="block-f113701e8d8007c257cd">
                    <div className="on-sqs-block-content">
                      <p className="" style={{ whiteSpace: 'pre-wrap' }}>
                        <strong>©eXp Realty LLC 2021</strong>
                      </p>
                      <p className="" style={{ whiteSpace: 'pre-wrap' }}>
                        All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="on-sqs-layout on-sqs-grid-12 on-columns-12"
              data-layout-label="Social"
              data-type="block-field"
              data-updated-on="1581521846125"
              id="ylopoFooterSocialBlock">
              <div className="on-row on-sqs-row">
                <div className="on-col on-sqs-col-12 on-span-12">
                  <div
                    className="on-sqs-block on-socialaccountlinks-v2-block on-sqs-block-socialaccountlinks-v2"
                    data-block-type="54"
                    id="block-yui_3_17_2_1_1581521331890_18662">
                    <div className="on-sqs-block-content">
                      <div className="on-sqs-svg-icon--outer on-social-icon-alignment-center on-social-icons-color-white on-social-icons-size-small on-social-icons-shape-circle on-social-icons-style-border">
                        <SocialLinks
                          className="header-left"
                          iconClassName="btn-social-icon"
                          iconTypeClassName="fab"
                          linkClassName="btn-social-link"
                          textClassName="btn-social-text"
                          showText={false}
                          target="_blank"
                          items={[
                            {
                              id: 0,
                              linkClassName: 'btn-instagram',
                              url: 'https://www.instagram.com/kinanbeck/',
                              iconClassName: 'instagram',
                              text: 'Instagram',
                            },
                            {
                              id: 1,
                              linkClassName: 'btn-linkedin',
                              url: 'https://www.linkedin.com/in/kinanbeck/',
                              iconClassName: 'linkedin',
                              text: 'LinkedIn',
                            },
                            {
                              id: 2,
                              linkClassName: 'btn-facebook',
                              url: 'https://www.facebook.com/kinanbeckrealestate/',
                              iconClassName: 'facebook',
                              text: 'Facebook',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="on-column-width-1 on-footer-spacer">&nbsp;</div>

          <div className="on-column-width-3 on-footer-office-hours-container" style={{ textAlign: 'left' }}>
            <div
              className="on-sqs-layout on-sqs-grid-12 on-columns-12 on-footer-popular-pages-head on-empty"
              data-layout-label="Popular Pages Heading"
              data-type="block-field"
              id="ylopoFooterPopularPagesHeadBlock">
              <div className="on-row on-sqs-row">
                <div className="on-col on-sqs-col-12 on-span-12" />
              </div>
            </div>

            <div className="on-footer-secondary-nav-list">
              <div id="secondaryNavWrapper" className="on-nav-wrapper" data-content-field="navigation-secondaryNav">
                <nav id="secondaryNavigation" data-content-field="navigation-secondaryNav">
                  <a
                    href="https://search.onesourceaustin.com/"
                    target="_blank"
                    rel="noreferrer"
                    data-uw-rm-brl="false"
                    aria-label="BUY - opens in new tab (opens in new tab)"
                    data-uw-rm-ext-link=""
                    uw-rm-external-link-id="https://search.onesourceaustin.com/$buy"
                    alt=" (opens in new tab)"
                    title=" (opens in new tab)">
                    BUY
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//home-valuation" data-uw-rm-brl="false">
                    SELL
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//communities" data-uw-rm-brl="false">
                    COMMUNITIES
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//testimonials" data-uw-rm-brl="false">
                    TESTIMONIALS
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//team-page" data-uw-rm-brl="false">
                    TEAM
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//new-contact-us" data-uw-rm-brl="false">
                    CONTACT US
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//blog" data-uw-rm-brl="false">
                    BLOG
                  </a>
                  <br role="presentation" data-uw-rm-sr="" />
                  <a href="https://www.onesourceaustin.com//" data-uw-rm-brl="false">
                    HOME
                  </a>
                </nav>
              </div>
            </div>

            <div
              className="on-sqs-layout on-sqs-grid-12 on-columns-12 on-footer-office-hours-head on-empty"
              data-layout-label="Office Hours Heading"
              data-type="block-field"
              id="ylopoFooterOfficeHoursHeadBlock">
              <div className="on-row on-sqs-row">
                <div className="on-col on-sqs-col-12 on-span-12" />
              </div>
            </div>

            <div
              className="on-sqs-layout on-sqs-grid-12 on-columns-12 on-footer-hours-list on-business-hours on-empty"
              data-layout-label="Office Hours List"
              data-type="block-field"
              id="ylopoAlternateFooterOfficeHoursList">
              <div>Monday - 9am - 6pm</div>
              <div>Tuesday - 9am - 6pm</div>
              <div>Wednesday - 9am - 6pm</div>
              <div>Thursday - 9am - 6pm</div>
              <div>Friday - 9am - 6pm</div>
              <div>Saturday - Closed</div>
              <div>Sunday - Closed</div>
            </div>
          </div>

          <div className="on-column-width-1 on-footer-spacer">&nbsp;</div>

          <div className="on-column-width-3 on-footer-site-address-container">
            <div className="on-footer-site-address">
              <div className="on-footer-site-info on-footer-address-title">The One Source Team</div>
              <div className="on-footer-site-info on-footer-address-line1">2003 North Lamar Boulevard Suite 200</div>
              <div className="on-footer-site-info on-footer-address-line2">Austin, TX, 78705</div>

              <div rel="tel" className="on-footer-site-info on-footer-site-phone">
                Phone:{' '}
                <a
                  href="tel:(512) 762-7001"
                  rel="tel"
                  data-uw-rm-brl="exc"
                  aria-label="call (512) 762-7001"
                  uw-rm-vague-link-id="tel:(512) 762-7001$call (512) 762-7001"
                  data-uw-rm-vglnk="">
                  (512) 762-7001
                </a>
              </div>

              <div className="on-footer-site-info on-footer-site-email">
                Email:
                <a
                  href="mailto:info@onesourceaustin.com"
                  data-uw-rm-brl="exc"
                  aria-label="send an email to info@onesourceaustin.com"
                  uw-rm-vague-link-id="mailto:info@onesourceaustin.com$send an email to info@onesourceaustin.com"
                  data-uw-rm-vglnk="">
                  info@onesourceaustin.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="on-privacy-policy-container">
        <hr role="presentation" data-uw-rm-sr="" />
        <br role="presentation" data-uw-rm-sr="" />
        <a href="https://www.onesourceaustin.com//#" className="on-privacy-policy-link" data-uw-rm-brl="false">
          Privacy Policy
        </a>
      </div>
      <div className="on-footer-IDX-compliance">
        <div>
          <div style={{ marginBottom: '15px' }}>
            Based on information from the Austin Board of REALTORS®. All information provided is deemed reliable but is
            not guaranteed and should be independently verified. The Austin Board of REALTORS®, ACTRIS and their
            affiliates provide the MLS and all content therein “AS IS” and without any warranty, express or implied.
          </div>
          <div style={{ marginBottom: '10px' }}>
            IDX information is provided exclusively for consumers’ personal, non-commercial use, that it may not be used
            for any purpose other than to identify prospective properties consumers may be interested in purchasing, and
            that the data is deemed reliable but is not guaranteed accurate by the MLS. Information last updated on Sun
            Jun 06 2021 22:54:34 GMT+0200 (srednjoeuropsko ljetno vrijeme).
          </div>
        </div>
        <br role="presentation" data-uw-rm-sr="" />
      </div>
    </footer>
  </Footer>
);

FooterContainer.propTypes = {
  children: PropTypes.node,
};

FooterContainer.defaultProps = {
  children: <>Footer Content</>,
};

export default memo(FooterContainer);
