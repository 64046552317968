import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SocialLinks } from '../social-links';
import './footer.scss';

const socials = [
  {
    id: 0,
    linkClassName: 'btn-facebook',
    url: 'https://www.facebook.com/',
    iconClassName: 'facebook',
    text: 'Facebook',
  },
  {
    id: 1,
    linkClassName: 'btn-instagram',
    url: 'https://www.instagram.com/',
    iconClassName: 'instagram',
    text: 'Instagram',
  },
  {
    id: 2,
    linkClassName: 'btn-twitter',
    url: 'https://www.twitter.com/',
    iconClassName: 'twitter',
    text: 'Twitter',
  },
  {
    id: 3,
    linkClassName: 'btn-linkedin',
    url: 'https://www.linkedin.com/',
    iconClassName: 'linkedin',
    text: 'LinkedIn',
  },
  {
    id: 4,
    linkClassName: 'btn-youtube',
    url: 'https://www.youtube.com/',
    iconClassName: 'youtube',
    text: 'Youtube',
  },
];

const Footer = (props) => {
  const { children } = props;
  return (
    <footer id="footer" className="on-footer">
      <div className="footer-container">{children}</div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = { children: null };

export default memo(Footer);
