import PropTypes from 'prop-types';
import cs from 'classnames';
import React, { memo } from 'react';
import HeroImage from '../../../images/hero.jpg';
import './hero-container.scss';

const HeroContainer = (props) => {
  const { openModalHandler } = props;

  return (
    <div className={cs('on-hero')}>
      <div className="section" id="hero">
        <picture>
          <img alt="Modern home" src={HeroImage} />
        </picture>
        <div className="content">
          <div className="intro-box">
            <h1>Now you can buy before you sell.</h1>
            <h3>
              Powered by HomeLight Trade-In<sup>TM</sup>
            </h3>
            <p>
              A calmer, more certain alternative to real estate. Avoid the stress, risk, and hassle of buying and
              selling at the same time. HomeLight will buy your current home giving you the freedom to buy your new
              home.
            </p>
            <a className="button" onClick={() => openModalHandler(true)}>
              Start here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

HeroContainer.defaultProps = {
  openModalHandler: () => {},
};

export default memo(HeroContainer);
