import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header as header, SocialLinks, MobileNav, DesktopNav } from '@components';

import './header-container.scss';

import { socials, menuItems } from '@constants';

const HeaderContainer = (props) => {
  const { children } = props;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = (e) => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <header>
      <a href="https://www.onesourceaustin.com/" data-uw-rm-brl="false">
        <img
          src="//static1.squarespace.com/static/5e0e87c3d133877c89324d51/t/5e4415ae481e936b2aaafa2b/1622614319549/?format=1500w"
          alt="The One Source Team"
          data-uw-rm-ima-original="the one source team"
        />
      </a>

      <div className="on-header-mobile on-header-menu-icon">
        <FontAwesomeIcon
          icon="bars"
          className={cs('on-hamburger-menu', 'on-header-menu-icon')}
          onClick={toggleNav}
          size="2x"
        />
      </div>

      <nav id="mainNavigation" data-content-field="navigation-mainNav">
        <div className="on-folder">
          <div className="on-folder-toggle" data-href="/communities-1">
            Communities
          </div>
          <div className="on-subnav">
            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/austin-real-estate" data-uw-rm-brl="false">
                Austin
              </a>
            </div>

            <div className="on-external">
              <a
                href="https://www.onesourceaustin.com/communities/austin-lake-hills-real-estate"
                data-uw-rm-brl="false">
                Auston Lake Hills
              </a>
            </div>

            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/balcones-village-real-estate" data-uw-rm-brl="false">
                Balcones Village
              </a>
            </div>

            <div className="on-external">
              <a
                href="https://www.onesourceaustin.com/communities/barton-creek-bluffs-real-estate"
                data-uw-rm-brl="false">
                Barton Creek Bluffs
              </a>
            </div>

            <div className="on-external">
              <a
                href="https://www.onesourceaustin.com/communities/barton-creek-lakeside-real-estate"
                data-uw-rm-brl="false">
                Barton Creek Lakeside
              </a>
            </div>

            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/barton-hills-real-estate" data-uw-rm-brl="false">
                Barton Hills
              </a>
            </div>

            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/bouldin-real-estate" data-uw-rm-brl="false">
                Bouldin
              </a>
            </div>

            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/brentwood-real-estate" data-uw-rm-brl="false">
                Brentwood
              </a>
            </div>

            <div className="on-external">
              <a href="https://www.onesourceaustin.com/communities/central-austin-real-estate" data-uw-rm-brl="false">
                Central Austin
              </a>
            </div>

            <div className="on-external">
              <a
                href="https://www.onesourceaustin.com/communities/central-east-austin-real-estate"
                data-uw-rm-brl="false">
                Central East Austin
              </a>
            </div>

            <div className="on-collection">
              <a href="https://www.onesourceaustin.com/communities" data-uw-rm-brl="false">
                More Communities...
              </a>
            </div>
          </div>
        </div>

        <div className="on-external">
          <a
            href="https://search.onesourceaustin.com/"
            target="_blank"
            data-uw-rm-brl="false"
            aria-label="Buy - opens in new tab (opens in new tab)"
            data-uw-rm-ext-link=""
            uw-rm-external-link-id="https://search.onesourceaustin.com/$buy"
            alt=" (opens in new tab)"
            title=" (opens in new tab)"
            rel="noreferrer">
            Buy
          </a>
        </div>

        <div className="on-folder">
          <div className="on-folder-toggle" data-href="/sell">
            Sell
          </div>
          <div className="on-subnav">
            <div className="on-external">
              <a href="https://www.onesourceaustin.com/home-valuation" data-uw-rm-brl="false">
                Home Valuation
              </a>
            </div>

            <div className="on-collection">
              <a href="https://www.onesourceaustin.com/why-list-with-us" data-uw-rm-brl="false">
                Why List With Us
              </a>
            </div>
          </div>
        </div>

        <div className="on-collection">
          <a href="https://www.onesourceaustin.com/virtual-home-buying" data-uw-rm-brl="false">
            Virtual Home Buying
          </a>
        </div>

        <div className="on-collection">
          <a href="https://www.onesourceaustin.com/testimonials" data-uw-rm-brl="false">
            Testimonials
          </a>
        </div>

        <div className="on-collection">
          <a href="https://www.onesourceaustin.com/team-page" data-uw-rm-brl="false">
            Team
          </a>
        </div>

        <div className="on-collection">
          <a href="https://www.onesourceaustin.com/blog" data-uw-rm-brl="false">
            Blog
          </a>
        </div>

        <div className="on-collection">
          <a href="https://www.onesourceaustin.com/new-contact-us" data-uw-rm-brl="false">
            Contact Us
          </a>
        </div>

        <div className="on-header-home">
          <a href="https://www.onesourceaustin.com/" data-uw-rm-brl="false">
            Home
          </a>
        </div>
      </nav>

      <MobileNav items={menuItems} className="on-mobile-menu" isOpen={mobileNavOpen} toggleNav={toggleNav} />
    </header>
  );
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

HeaderContainer.defaultProps = { children: null };

export default memo(HeaderContainer);
