export const menuItems = [
  {
    id: 1,
    htmlId: 'home-menu-id',
    displayName: 'Home', // TODO: I18n this
    url: 'https://www.onesourceaustin.com/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 2,
    htmlId: 'menu-home',
    displayName: 'Communities', // TODO: I18n this
    url: '#',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 201,
        htmlId: 'menu-listings-active',
        displayName: 'Austin', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/austin-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 202,
        htmlId: 'menu-listings-active',
        displayName: 'Auston Lake Hills', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/austin-lake-hills-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 203,
        htmlId: 'menu-listings-active',
        displayName: 'Balcones Village', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/balcones-village-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 204,
        htmlId: 'menu-listings-active',
        displayName: 'Barton Creek Bluffs', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/barton-creek-bluffs-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 205,
        htmlId: 'menu-listings-active',
        displayName: 'Barton Creek Lakeside', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/barton-creek-lakeside-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 206,
        htmlId: 'menu-listings-active',
        displayName: 'Barton Hills', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/barton-hills-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 207,
        htmlId: 'menu-listings-active',
        displayName: 'Bouldin', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/bouldin-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 208,
        htmlId: 'menu-listings-active',
        displayName: 'Brentwood', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/brentwood-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 209,
        htmlId: 'menu-listings-active',
        displayName: 'Central Austin', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/central-austin-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 210,
        htmlId: 'menu-listings-active',
        displayName: 'Central East Austin', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities/central-east-austin-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 211,
        htmlId: 'menu-listings-active',
        displayName: 'More Communities...', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/communities',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: false,
  },
  {
    id: 3,
    htmlId: 'menu-buy',
    displayName: 'Buy', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-sell',
    displayName: 'Sell', // TODO: I18n this
    url: '#',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 401,
        htmlId: 'menu-listings-active',
        displayName: 'Home Valuation', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/home-valuation',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 402,
        htmlId: 'menu-listings-active',
        displayName: 'Why List With Us', // TODO: I18n this
        url: 'https://www.onesourceaustin.com/why-list-with-us',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-sellers',
    displayName: 'Virtual Home Buying',
    url: 'https://www.onesourceaustin.com/virtual-home-buying',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 6,
    htmlId: 'menu-for-lease',
    displayName: 'Testimonials',
    url: 'https://www.onesourceaustin.com/testimonials',
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 7,
    htmlId: 'menu-communities',
    displayName: 'Team',
    url: 'https://www.onesourceaustin.com/team-page',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 8,
    htmlId: 'menu-concierge',
    displayName: 'Blog',
    url: 'https://www.onesourceaustin.com/blog',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 9,
    htmlId: 'menu-contact',
    displayName: 'Contact Us',
    url: 'https://www.onesourceaustin.com/new-contact-us',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];
